<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    color="sidebar"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="900"
    app
    width="230"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list
      dense
      nav
      class="px-0"
      style="border-radius: 0px !important; background: white"
    >
      <v-list-item>

        <v-list-item-content>
          <v-list-item-title
            class="d-flex justify-center pt-1">
              <v-img
            :src="require('@/assets/Todo-Cartas-Horizontal-White.png')"
             max-width="160"
          />
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list
      expand
      nav
      class="px-0"
      style="border-radius: 0px !important"
    >

      <template v-for="(item, i) in computedItems">
        <template v-if="item.children">
           <base-item-group
            v-if="item.permission == 'default' || $canSubmenu(item.permission)"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
        </template>
        <template v-else>
          <base-item
            v-if="item.permission == 'default' || $can(item.permission)"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
        <!-- <template v-if="item.permission == 'default' || $can(item.permission) ">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
          />
        </template> -->
      </template>
      <div />
    </v-list>

    <template v-slot:append>
       <v-list-item
          @click="logout"
        >
          <v-list-item-content>
            <v-list-item-title class="text-center text-uppercase">
               <v-icon class="d-block my-2" color="white">mdi-logout-variant</v-icon>
                salir
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   colorIcon:'info',
        //   title: 'Inicio',
        //   to: '/dashboard',
        //   permission: 'default'
        // },
        {
          icon: 'mdi-seed',
          colorIcon:'info',
          title: 'Inicio',
          to: '/tc-admin/principal',
          permission: 'Mostrar menu'
        },
        {
          icon: 'mdi-clipboard-text-outline',
          colorIcon:'info',
          title: 'Menús',
          permission:'Mostrar menu',
          children:[
            {
              icon: '',
              colorIcon:'info',
              title: 'Lista',
              to: '/tc-admin/cartas',
              permission:'Mostrar menu'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Nuevo',
              to: '/tc-admin/carta/crear',
              permission:'Crear menu'
            },
          ]
        },
        {
          icon: 'mdi-pasta',
          colorIcon:'info',
          title: 'Platos',
          to: '',
          permission: 'Mostrar plato',
          children:[
            {
              icon: '',
              colorIcon:'info',
              title: 'Lista',
              to: '/tc-admin/platos',
              permission:'Mostrar plato'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Nuevo',
              to: '/tc-admin/platos/crear',
              permission:'Crear plato'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Categorias',
              to: '/tc-admin/categorias',
              permission:'Mostrar categoria'
            },
          ]
        },
        {
          icon: 'mdi-image-album',
          colorIcon:'info',
          title: 'Promociones',
          to: '',
          permission: 'Crear promocion',
          children:[
            {
              icon: '',
              colorIcon:'info',
              title: 'Lista',
              to: '/tc-admin/promociones',
              permission:'Mostrar promocion'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Nuevo',
              to: '/tc-admin/promociones/crear',
              permission:'Crear promocion'
            },
          ]
        },
        {
          icon: 'mdi-calendar-outline',
          colorIcon:'info',
          title: 'Horarios',
          to: '',
          permission: 'Crear promocion',
          children:[
            {
              icon: '',
              colorIcon:'info',
              title: 'Ver horarios',
              to: '/tc-admin/horarios',
              permission:'Mostrar promocion'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Ver reservas',
              to: '/tc-admin/reservas',
              permission:'Mostrar promocion'
            }
          ]
        },
        {
          icon: 'mdi-account-outline',
          colorIcon:'info',
          title: 'Usuarios',
          to: '/tc-admin/usuarios',
          permission:'Mostrar usuarios'
        },
        {
          icon: 'mdi-cog-transfer-outline',
          colorIcon:'info',
          title: 'Configuración',
          to: '/tc-admin/configuraciones',
          permission:'default',
        },
      ],

      items_admin: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   colorIcon:'info',
        //   title: 'Inicio',
        //   to: '/dashboard',
        //   permission: 'default'
        // },
        {
          icon: 'mdi-silverware',
          colorIcon:'info',
          title: 'Restaurante',
          to: '',
          permission: 'Mostrar restaurante',
          children:[
            {
              icon: '',
              colorIcon:'info',
              title: 'Lista',
              to: '/tc-admin/restaurantes',
              permission:'Mostrar restaurante'
            },
            {
              icon: '',
              colorIcon:'info',
              title: 'Nuevo',
              to: '/tc-admin/restaurante/registrar',
              permission:'Crear restaurante'
            },
          ]
        },

        {
          icon: 'mdi-seed',
          colorIcon:'info',
          title: 'Alérgenos',
          to: '/tc-admin/alergenos',
          permission: 'Mostrar alergeno'
        },
        {
          icon: 'mdi-account-outline',
          colorIcon:'info',
          title: 'Usuarios',
          to: '/tc-admin/usuarios',
          permission:'Mostrar usuarios'
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.$store.getters['auth/isAdmin'] ? this.items_admin.map(this.mapItem) : this.items.map(this.mapItem);
      },
      profile () {
        return {
          avatar: true,
          title: 'excoje',
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          // title:this.$t(`default.sidebar_restaurant.${item.title}`),
          title:item.title,
        }
      },
      logout(){
        this.$store.commit('SET_OVERLAY', true);
        this.$store.dispatch('auth/logout').then(()=>{
          this.$router.push({path: '/auth/login'});
          // this.$store.commit('SET_LOAD', false);
        })
        .catch(() =>{
          this.$store.commit('SET_OVERLAY', false);
        });
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .05

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
